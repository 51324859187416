import { Box, Card } from '@mui/material';
import './Forløb.css';
import React from 'react';

const Forløb: React.FunctionComponent = () => {
    return (
        <Box sx={{width: "80%", margin: "auto", paddingTop: "85px", minHeight: "80vh"}}>
            <h1>Jeg tilbyder</h1>
            <Card >
                
            </Card>
        </Box>
    );
};

export default Forløb;